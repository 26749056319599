<template>
  <div class="order-item-page" :class="{ loading: isLoading }">
    <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
    <div class="order-item-page__top">
      <div class="left-container">
        <div class="order-item-page__header">
          <custom-header :title="'Новый заказ №' + id"></custom-header>
          <status-container :statuses="status"></status-container>
        </div>
        <p class="author-line">
          Автор заказа<span>{{ author }}</span>
        </p>
        <p class="number-line" v-if="trackNumber">
          Трек-номер<span>{{ trackNumber }}</span>
        </p>
        <alert-block
          v-show="isHaveProblem"
          :text="`Отгрузка временно недоступна, есть действующие ограничения. Свяжитесь с менеджером, чтобы узнать причину`"
        ></alert-block>
        <alert-block
          v-show="this.status.includes(15)"
          :text="`Пожалуйста, подождите несколько минут. Если статус заказа не обновится автоматически, обновите страницу или попробуйте проверить позже`"
        ></alert-block>
        <div class="docs-container">
          <section
            class="doc-item"
            :class="{ loading: !isCommercialOffer || isCommercialOfferLoading }"
          >
            <p class="doc-item__name">Коммерческое предложение</p>
            <div class="doc-item__container">
              <div class="doc-item__view">
                <template v-if="!isCommercialOffer">
                  <!-- {{ commercial_offer.name }} -->
                  КП
                </template>
                <img
                  v-show="isCommercialOffer || !isCommercialOfferLoading"
                  class="doc-item__view-placeholder"
                  src="@/assets/icons/kp-placeholder.svg"
                  alt=""
                />
                <v-loading
                  :isLoading="isCommercialOfferLoading"
                  :color="'gray'"
                  :size="'little'"
                ></v-loading>
              </div>
              <div class="doc-item__btns">
                <a :href="commercial_offer.link" target="_blank"><span>Открыть</span></a>
                <a :href="commercial_offer.link" target="_blank" download>
                  <span>Скачать</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 12L12 17M12 17L17 12M12 17L12 4"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 20H18"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <a :href="commercial_offer.link" target="_blank">
                  <span>Распечатать</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 18H5C3.89543 18 3 17.186 3 16.1818V9.81818C3 8.81403 3.89543 8 5 8H19C20.1046 8 21 8.81403 21 9.81818V16.1818C21 17.186 20.1046 18 19 18H16.5"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 3H17V8H7V3Z"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 11H13"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 15H17V21H7V15Z"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </section>
          <section
            v-if="!status.includes(777)"
            class="doc-item"
            :class="{ loading: !isPaymentCheck || isPaymentCheckLoading }"
          >
            <p class="doc-item__name">Счет на оплату</p>
            <div class="doc-item__container">
              <div class="doc-item__view" :class="{ loading: isPaymentCheckLoading }">
                <template v-if="!isPaymentCheck">
                  <!-- {{ payment_check.name }} -->
                  Счёт на оплату
                </template>
                <img
                  v-show="isPaymentCheck || !isPaymentCheckLoading"
                  class="doc-item__view-placeholder"
                  src="@/assets/icons/bill-placeholder.svg"
                  alt=""
                />
                <v-loading
                  :isLoading="isPaymentCheckLoading"
                  :color="'gray'"
                  :size="'little'"
                ></v-loading>
              </div>
              <div class="doc-item__btns">
                <a :href="payment_check.link" target="_blank">
                  <span>Открыть</span>
                </a>
                <a :href="payment_check.link" download target="_blank">
                  <span>Скачать</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 12L12 17M12 17L17 12M12 17L12 4"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 20H18"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <a :href="payment_check.link" target="_blank">
                  <span>Распечатать</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 18H5C3.89543 18 3 17.186 3 16.1818V9.81818C3 8.81403 3.89543 8 5 8H19C20.1046 8 21 8.81403 21 9.81818V16.1818C21 17.186 20.1046 18 19 18H16.5"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 3H17V8H7V3Z"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 11H13"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 15H17V21H7V15Z"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </section>
          <section v-if="!status.includes(777) && rpd.link" class="doc-item">
            <p class="doc-item__name">УПД</p>
            <div class="doc-item__container">
              <div class="doc-item__view">
                <template>
                  <!-- {{ payment_check.name }} -->
                  УПД
                </template>
                <!-- <img
                  v-show="isPaymentCheck || !isPaymentCheckLoading"
                  class="doc-item__view-placeholder"
                  src="@/assets/icons/bill-placeholder.svg"
                  alt=""
                />
                <v-loading
                  :isLoading="isPaymentCheckLoading"
                  :color="'gray'"
                  :size="'little'"
                ></v-loading> -->
              </div>
              <div class="doc-item__btns">
                <a :href="rpd.link" target="_blank">
                  <span>Открыть</span>
                </a>
                <a :href="rpd.link" download target="_blank">
                  <span>Скачать</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 12L12 17M12 17L17 12M12 17L12 4"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 20H18"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <a :href="rpd.link" target="_blank">
                  <span>Распечатать</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 18H5C3.89543 18 3 17.186 3 16.1818V9.81818C3 8.81403 3.89543 8 5 8H19C20.1046 8 21 8.81403 21 9.81818V16.1818C21 17.186 20.1046 18 19 18H16.5"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 3H17V8H7V3Z"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 11H13"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 15H17V21H7V15Z"
                      stroke="#424F60"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div
        class="right-container"
        v-if="
          (isForwardOption || isEditOption || isCancelOption || isRepeatOption) &&
          !status.includes(777)
        "
      >
        <section class="button-block">
          <button
            class="button-block__button red"
            v-if="isForwardOption"
            :class="{ disabled: isHaveProblem || !isSupplyAgreement, loading: isButtonLoading }"
            @click="confirmOrder()"
          >
            Подтвердить заказ и счёт в оплату
            <v-loading :isLoading="isButtonLoading" :color="'gray'" :size="'little'"></v-loading>
          </button>
          <button class="button-block__button" v-if="isEditOption" @click="editOrder()">
            Редактировать заказ
          </button>
          <button v-if="isCancelOption" @click="cancelOrder()" class="button-block__button">
            Отменить заказ
          </button>
          <button v-if="isRepeatOption" @click="" class="button-block__button">
            Повторить заказ
          </button>
        </section>
        <alert-block :text="''" v-if="!isSupplyAgreement">
          <p>
            Подтвердите условия и договор поставки в разделе
            <router-link
              :to="{
                name: 'documents'
              }"
              >Документы</router-link
            >
          </p>
        </alert-block>
      </div>
    </div>
    <section class="specification-block">
      <h2>Спецификация</h2>
      <div class="specifications-table-container">
        <table class="specifications-table" :class="{ shown: isAllShown }">
          <tr>
            <th class="first">№</th>
            <th>Номенклатура</th>
            <th>Артикул</th>
            <th class="action">Действия</th>
            <th class="content">Содержание</th>
            <th class="price">Цена</th>
            <th class="amount">Количество</th>
            <th class="unit">Ед. изм.</th>
          </tr>
          <order-specification-row
            v-for="(specification, number) in specifications"
            :number="number + 1"
            :id="specification.id"
            :name="specification.name"
            :articul="specification.articul"
            :action="'К опеспечению'"
            :price="specification.price"
            :content="'Содержание'"
            :amount="specification.availability"
          ></order-specification-row>
        </table>
        <button
          v-if="specifications.length > 1"
          class="show-all-btn"
          :class="{ shown: isAllShown }"
          @click="isAllShown = !isAllShown"
        >
          <span class="not-shown">Раскрыть всю спецификацию</span>
          <span class="shown">Скрыть всю спецификацию</span>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 11L12.5 16L17.5 11"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <table
          class="specifications-table summary"
          :class="{ 'top-border': specifications.length <= 1 }"
        >
          <tr>
            <th>Всего позиций</th>
            <th>Скидка</th>
            <th>НДС</th>
            <th class="last">Итого с НДС</th>
          </tr>
          <tr>
            <td>{{ getPositionSum }}</td>
            <td>{{ parseInt(discount_sum).toLocaleString('ru-RU') }}</td>
            <td>{{ parseInt(nds_sum).toLocaleString('ru-RU') }}</td>
            <td>{{ parseInt(final_sum).toLocaleString('ru-RU') }} руб.</td>
          </tr>
        </table>
      </div>
    </section>
    <div class="availability-status" v-if="!status.includes(6) || trackNumber">
      <svg
        v-if="!status.includes(6)"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 12.0001L8.94975 16.9499L19.5563 6.34326"
          stroke="#035AA6"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p v-if="!status.includes(6)">В наличии в полном объеме</p>
      <p v-else-if="trackNumber">Трек номер: {{ trackNumber }}</p>
    </div>
    <p class="nearest-date" v-if="!status.includes(777)">
      <template v-if="!status.includes(6)">
        Ближайшая дата отгрузки: в течение 2-х рабоичх дней с даты подтвреждения заказа
      </template>
      <template v-else-if="shipmentMethod"> Способ доставки: {{ shipmentMethod }} </template>
    </p>
    <section class="comments-block">
      <h2>Комментарий</h2>
      <p v-if="comment.length > 0" class="comment">{{ comment }}</p>
      <p v-else class="none-comment">Комментарий отсутствует</p>
    </section>
  </div>
</template>

<script>
import CustomHeader from '../components/CustomHeader.vue'
import InfoTooltip from '../components/InfoTooltip.vue'
import vRadio from '../components/v-radio.vue'
import vSelect from '../components/v-select.vue'
import OrderHistoryItem from '../components/OrderHistoryItem.vue'
import vPagination from '../components/v-pagination.vue'
import StatusContainer from '../components/StatusContainer.vue'
import AlertBlock from '../components/AlertBlock.vue'
import OrderSpecificationRow from '../components/OrderSpecificationRow.vue'
import vLoading from '../components/v-loading.vue'

import { mapState, mapWritableState, mapActions } from 'pinia'
import { useOrderInfo } from '@/stores/orderInfo'
import { useBreadcrumbs } from '../stores/breadcrumbs'

import { serverRequest } from '../api/request'

export default {
  name: 'OrderItemView',
  components: {
    CustomHeader,
    InfoTooltip,
    vRadio,
    vSelect,
    OrderHistoryItem,
    vPagination,
    AlertBlock,
    OrderSpecificationRow,
    vLoading,
    StatusContainer
  },

  props: {
    id: {
      type: String,
      default() {
        return 'standart_id'
      }
    }
  },

  data() {
    return {
      isAllShown: false,

      isLoading: false,

      isHaveProblem: false,

      isSupplyAgreement: false,

      isSupplyAgreementLoading: false,

      commercial_offer: {
        name: 'КП',
        link: ''
      },
      payment_check: {
        name: 'счёт',
        link: ''
      },
      rpd: {
        name: 'УПД',
        link: null
      },
      status: [1],
      author: 'Автор',
      trackNumber: null,
      shipmentMethod: null,
      discount_sum: 0,
      nds_sum: 0,
      final_sum: 0,
      shipment_date: '01/01/2023',
      comment: '',

      specifications: [
        {
          id: 0,
          name: 'Фреза сферическая',
          articul: 'FBK0500219',
          action: 'К опеспечению',
          content: 'Содержание',
          amount: 2000,
          unit: 'м2'
        }
      ],

      isCommercialOfferLoading: false,
      isPaymentCheckLoading: false,

      orderFilesInterval: null,
      orderStatusInterval: null
    }
  },

  created() {
    this.$watch(
      () => this.id,
      (toParams, previousParams) => {
        this.getOrderInfo()
      }
    )
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'order-history',
        name: 'Мои заказы'
      },
      {
        path: 'order-item',
        name: 'Заказ'
      }
    ]
    this.getOrderInfo()
    this.checkOrderFiles(0)
    this.checkOrderStatus(15000)
    this.getSupplyAgreement()
  },

  mounted() {},

  beforeUnmount() {
    clearTimeout(this.orderFilesInterval)
    clearTimeout(this.orderStatusInterval)
  },

  computed: {
    ...mapWritableState(useOrderInfo, { storeOrderNumber: 'orderNumber' }),
    ...mapWritableState(useOrderInfo, { storeOrderDate: 'orderDate' }),
    ...mapWritableState(useOrderInfo, { storeOrderProducts: 'orderProducts' }),
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),

    getPositionSum() {
      return this.specifications.length
    },

    isCommercialOffer() {
      if (this.commercial_offer.link) {
        return true
      } else {
        return false
      }
    },

    isPaymentCheck() {
      if (this.payment_check.link) {
        return true
      } else {
        return false
      }
    },

    isButtonLoading() {
      if (this.isSupplyAgreementLoading) {
        return true
      } else {
        return false
      }
    },

    // isPaymentCheckLoading() {
    //   if (this.status.includes(1) && this.payment_check.link == '') {
    //     return true
    //   } else {
    //     return false
    //   }
    // },

    isForwardOption() {
      if (this.status.includes(1) && !this.status.includes(14)) {
        return true
      } else {
        return false
      }
    },

    isEditOption() {
      if (this.status.includes(0)) {
        return true
      } else {
        return false
      }
    },

    isRepeatOption() {
      if (this.status.includes(10)) {
        return true
      } else {
        return false
      }
    },

    isCancelOption() {
      if (this.status.includes(0) || this.status.includes(1)) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    ...mapActions(useOrderInfo, { storeUpdateGoodAmount: 'updateGoodAmount' }),

    async getSupplyAgreement() {
      this.isSupplyAgreementLoading = true

      let result = await serverRequest('POST', 'json', 'documents/get_supply_agreement_status')

      this.isSupplyAgreement = result.supply_agreement_status

      this.isSupplyAgreementLoading = false
    },

    async getOrderInfo(onlyStatus = false) {
      if (!onlyStatus) this.isLoading = true

      let formData = {
        order_id: this.id
      }

      let result = await serverRequest('POST', 'json', 'get_order_info', formData)

      if (result != 'error' && result != 'unathorized') {
        if (!onlyStatus) {
          this.commercial_offer.name = result.commercial_offer_name
          this.commercial_offer.link = result.commercial_offer_link
          this.payment_check.name = result.payment_check_name
          this.payment_check.link = result.payment_check_link
          this.rpd.link = result.rpd

          this.author = result.author
          this.trackNumber = result.track_number
          this.specifications = result.specification
          // this.discount_sum = result.discount_sum
          // this.nds_sum = result.nds_sum
          this.final_sum = result.final_sum
          this.shipment_date = result.shipment_date
          this.comment = result.comment
          this.trackNumber = result.track_number
          this.shipmentMethod = result.shipment_method
        }

        this.status = result.order_status

        if (this.status.includes(9) || this.status.includes(2)) {
          this.isHaveProblem = true
        } else {
          this.isHaveProblem = false
        }
      } else {
        //alert('Ошибка запроса')
      }

      if (!onlyStatus) this.isLoading = false
    },

    async confirmOrder() {
      console.log('click')

      this.isLoading = true

      let formData = {
        order_id: this.id
      }

      let result = await serverRequest('POST', 'json', 'confirm_order', formData)

      if (result != 'error' && result != 'unathorized') {
        this.getOrderInfo()

        setTimeout(() => {
          this.getOrderInfo()
        }, 120000)
      } else {
        //alert('Ошибка запроса')
      }
    },

    checkOrderFiles(time) {
      console.log(this.isCommercialOffer)
      console.log(this.commercial_offer.link)

      if (!this.isPaymentCheck || !this.isCommercialOffer) {
        this.orderFilesInterval = setTimeout(() => {
          try {
            this.getOrderFiles()
          } catch {
            alert('Ошибка загрузки документов с сервера')
          }
        }, time)
      } else {
        clearInterval(this.orderFilesInterval)
      }
    },

    checkOrderStatus(time) {
      this.orderStatusInterval = setInterval(() => {
        try {
          this.getOrderInfo(true)
        } catch {
          alert('Ошибка загрузки данных заказа с сервера')
        }
      }, time)
    },

    checkDocsLoadingStatus() {
      if (!this.isCommercialOffer) {
        this.isCommercialOfferLoading = true
      } else {
        this.isCommercialOfferLoading = false
      }

      if (!this.isPaymentCheck) {
        this.isPaymentCheckLoading = true
      } else {
        this.isPaymentCheckLoading = false
      }
    },

    async getOrderFiles() {
      this.checkDocsLoadingStatus()

      let formData = {
        order_id: this.id
      }

      let result = await serverRequest('POST', 'json', 'get_order_files', formData)

      console.log(result.check)

      if (result.offer) {
        this.commercial_offer.link = result.offer
      }

      if (result.check) {
        this.payment_check.link = result.check
      }

      this.checkDocsLoadingStatus()

      this.checkOrderFiles(5000)
    },

    async editOrder() {
      console.log('click')

      this.isLoading = true

      let formData = {
        order_id: this.id
      }

      let result = await serverRequest('POST', 'json', 'check_order', formData)

      if (result != 'error' && result != 'unathorized') {
        this.storeOrderNumber = this.id
        this.storeOrderDate = result.order_date
        this.storeOrderProducts = this.specifications

        for (let good of result.products) {
          this.storeUpdateGoodAmount(good.id, good.price, good.availability, good.quantity)
        }

        this.$router.push({ name: 'order' })
      } else {
        //alert('Ошибка запроса')
      }
    },

    async cancelOrder() {
      this.isLoading = true

      let formData = {
        order_id: this.id
      }

      let result = await serverRequest('POST', 'json', 'cancel_order', formData)

      if (result != 'error' && result != 'unathorized') {
        this.getOrderInfo()
      } else {
        //alert('Ошибка запроса')
      }
    }
  }
}
</script>

<style lang="scss">
.order-item-page {
  @extend %white-bg-block;

  width: 100%;

  position: relative;

  &.loading {
    pointer-events: none;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__header {
    display: flex;
    column-gap: 32px;
    align-items: flex-start;
  }

  .left-container {
    .alert-block {
      margin-top: 12px;
      max-width: 600px;
    }
  }

  .right-container {
    max-width: 400px;
    width: 100%;

    .alert-block {
      margin-top: 16px;
    }
  }

  .button-block {
    width: 100%;

    padding: 24px;

    border-radius: 6px;

    background-color: var(--bw4);

    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &__button {
      position: relative;

      @extend %text-regular;
      color: var(--bw6);
      text-align: center;

      padding: 12px 16px;

      border-radius: 4px;

      background-color: var(--bw1);

      &.red {
        background-color: var(--primary);
      }

      &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }

      &.loading {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }

  .author-line,
  .number-line {
    @extend %text-regular;
    color: var(--bw1);

    span {
      @extend %text-regular;
      color: var(--bw0);

      margin-left: 12px;
    }
  }

  .number-line {
    margin-top: 12px;
  }

  .author-line {
    margin-top: 32px;
  }

  .docs-container {
    margin-top: 48px;

    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 24px;

    .doc-item {
      &__name {
        @extend %text-medium;
        color: $bw0;
      }

      &__container {
        margin-top: 12px;

        display: flex;
        column-gap: 12px;

        position: relative;
      }

      &__view {
        max-width: 103px;
        width: 103px;

        padding: 0 10px;

        background-color: transparent;

        text-align: center;

        word-break: break-word;

        @extend %text-regular;
        color: $bw3;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
      }

      &__view-placeholder {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        object-fit: contain;
      }

      &__btns {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        button,
        a {
          display: flex;

          column-gap: 4px;

          width: fit-content;

          padding: 6px 12px;

          border-radius: 6px;

          background-color: $bw4;

          span {
            @extend %text-regular;
            color: $bw1;
          }
        }
      }

      &.loading {
        .doc-item__view {
          background-color: $bw4;
        }

        .doc-item__btns {
          a {
            opacity: 0.7;
            pointer-events: none;
          }
        }
      }
    }
  }

  .specification-block {
    margin-top: 48px;

    h2 {
      @extend %text-medium;
      color: $bw0;
    }
  }

  .specifications-table-container {
    margin-top: 16px;

    border: 1px solid var(--bw3);
    background-color: var(--bw6);

    &.summary {
      margin-top: 48px;
    }
  }

  .specifications-table {
    width: 100%;

    border-collapse: collapse;

    th {
      padding: 12px 27px 12px 27px;

      border-right: 1px solid var(--bw3);
      border-bottom: 1px solid var(--bw3);

      @extend %text-regular;
      color: var(--bw0);

      text-align: left;

      &.first {
        padding: 12px 26px 12px 26px;
        margin-right: 20px;

        width: 90px;

        border-radius: 6px 0 0 0;
      }

      &.price {
        padding: 12px;
        text-align: center;
      }

      &.amount {
        width: 160px;
        text-align: center;
      }

      &.unit {
        border-right: none;
        width: 110px;
        text-align: right;
        padding-right: 17px;
      }

      &.last {
        border-right: none;
        width: 48px;
        padding: 12px 12px;
      }
    }

    &.summary {
      th {
        padding: 12px;
        text-align: center;
        width: 25%;
      }

      td {
        padding: 12px;
        text-align: center;

        @extend %text-regular;
        color: $bw0;
      }

      &.top-border {
        border-top: 1px solid var(--bw3);
      }
    }

    .order-specification-row {
      &:not(:nth-child(2)) {
        display: none;
      }
    }

    &.shown {
      .order-specification-row {
        &:not(:nth-child(2)) {
          display: table-row;
        }
      }
    }
  }

  .show-all-btn {
    display: flex;
    column-gap: 12px;
    justify-content: center;
    align-items: center;

    padding: 8px;

    width: 100%;

    background-color: $bw4;

    border-top: 1px solid $bw3;
    border-bottom: 1px solid $bw3;

    span {
      @extend %text-regular;
      color: var(--blue);
    }

    .not-shown {
      display: block;
    }

    .shown {
      display: none;
    }

    svg {
      transition: transform 0.3s;
    }

    &.shown {
      .not-shown {
        display: none;
      }

      .shown {
        display: block;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }

  .availability-status {
    margin-top: 24px;

    display: flex;
    column-gap: 12px;
    align-items: center;

    padding: 12px 16px;

    width: fit-content;

    background-color: $bw4;

    border-radius: 4px;

    p {
      @extend %text-regular;
      color: $bw0;
    }
  }

  .nearest-date {
    margin-top: 12px;

    @extend %text-regular;
    color: $bw0;
  }

  .comments-block {
    margin-top: 48px;
    max-width: 800px;

    h2 {
      @extend %text-medium;
      color: $bw0;
    }

    .comment {
      @extend %text-regular;
      color: $bw0;

      margin-top: 12px;

      padding: 16px;

      border-radius: 6px;

      border: 1px solid $bw3;
    }

    .none-comment {
      margin-top: 16px;
      @extend %text-regular;
    }
  }
}

@media screen and (max-width: 1600px) {
  .order-item-page {
    .specifications-table {
      th {
        padding: 12px 18px 12px 18px;

        &.first {
          padding: 12px 26px 12px 26px;
          margin-right: 20px;

          width: 70px;
        }

        &.price {
          text-align: center;
        }

        &.amount {
          width: 140px;
          text-align: center;
        }

        &.unit {
          border-right: none;
          width: 110px;
          text-align: right;
          padding-right: 17px;
        }

        &.last {
          width: 48px;
          padding: 12px 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .order-item-page {
    &__header {
      flex-direction: column;
      row-gap: 16px;
    }

    .docs-container {
      flex-direction: column;
      row-gap: 24px;
    }

    .specifications-table {
      width: 100%;

      border-collapse: collapse;

      th {
        padding: 12px 8px;

        &.first {
          padding: 12px;

          width: 40px;
        }

        &.price {
          text-align: center;
        }

        &.amount {
          width: 120px;
          text-align: center;
        }

        &.unit {
          border-right: none;
          width: 90px;
          text-align: center;
          padding-right: 10px;
        }

        &.last {
          border-right: none;
          width: 48px;
          padding: 12px 12px;
        }
      }

      td {
        padding: 12px 8px;

        &.number {
          text-align: center;
        }

        &.unit {
          text-align: center;
        }
      }

      &.summary {
        th {
          padding: 12px;
          text-align: center;
          width: 25%;
        }

        td {
          padding: 12px 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .order-item-page {
    .docs-container {
      flex-direction: row;
    }

    &__top {
      flex-direction: column;
      row-gap: 48px;
    }

    .right-container {
      max-width: 100%;
    }

    .specification-block {
      display: none;
    }

    .specifications-table {
      display: none;
    }

    .availability-status {
      display: none;
    }

    .nearest-date {
      display: none;
    }

    .comments-block {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .order-item-page {
    .docs-container {
      flex-direction: column;
    }
  }
}
</style>
