import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { useUserToken } from '@/stores/userToken'

import { serverRequest } from '@/api/request.js'

export const useGeneralInfo = defineStore('generalInfo', () => {
  const userToken = useUserToken()

  const mainBanner = ref('')
  const learningBanner = ref('')
  const sideBanner = ref('')
  const priceList = ref('')
  const specialization = ref('')
  const specialOrderSended = ref(false)

  const storageMainBanner = sessionStorage.getItem('mainBanner')
  const storageSideBanner = sessionStorage.getItem('sideBanner')
  const storagePricelist = sessionStorage.getItem('priceList')
  const storageSpecialization = sessionStorage.getItem('specialization')
  const storageLearningBanner = sessionStorage.getItem('learningBanner')

  if (storageMainBanner) {
    mainBanner.value = JSON.parse(storageMainBanner)
  } else {
    mainBanner.value = {
      banner: '',
      link: '/'
    }
  }

  if (storageLearningBanner) {
    learningBanner.value = JSON.parse(storageLearningBanner)
  } else {
    learningBanner.value = null
  }

  // if (storagePricelist) {
  //   priceList.value = JSON.parse(storagePricelist)
  // } else {
  serverRequest('GET', 'json', 'general/pricelist').then((result) => {
    if (result != 'error' && result != 'unathorized') {
      priceList.value = result.result
      sessionStorage.setItem('priceList', JSON.stringify(result.result))
    }
  })
  // }

  // if (storageSpecialization) {
  //   specialization.value = JSON.parse(storageSpecialization)
  // } else {
  serverRequest('GET', 'json', 'general/template_specialization').then((result) => {
    if (result != 'error' && result != 'unathorized') {
      specialization.value = result.result
      sessionStorage.setItem('specialization', JSON.stringify(result.result))
    }
  })
  // }

  if (storageSideBanner) {
    sideBanner.value = JSON.parse(storageSideBanner)
  } else {
    sideBanner.value = {
      banner: '',
      link: '/'
    }
  }

  watch(
    mainBanner,
    async (newBanner, oldBanner) => {
      sessionStorage.setItem('mainBanner', JSON.stringify(newBanner))
    },
    { deep: true }
  )

  watch(
    sideBanner,
    async (newBanner, oldBanner) => {
      sessionStorage.setItem('sideBanner', JSON.stringify(newBanner))
    },
    { deep: true }
  )

  watch(learningBanner, async (newBanner, oldBanner) => {
    sessionStorage.setItem('learningBanner', JSON.stringify(newBanner))
  })

  return {
    mainBanner,
    sideBanner,
    specialOrderSended,
    learningBanner,
    priceList,
    specialization
  }
})
